import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { AudioPlayer } from "./AudioPlayer";
import axios from "axios";
import fileDownload from "js-file-download";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 244, 0);
  padding: 10px;
  font-family: "dashDigital";
  font-weight: bold;
  color: #000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonOparator = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 244, 0);
  padding: 10px;
  font-family: "dashDigital";
  font-weight: bold;
  color: #000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonOparatorOff = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 0, 0);
  padding: 10px;
  font-family: "dashDigital";
  font-weight: bold;
  color: rgb(0, 244, 0);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledInput = styled.input`
  display: block;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 244, 0);
  padding: 10px;
  font-weight: bold;
  color: #000;
  font-family: "dashDigital";

  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonB = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonPS = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveFooter = styled.div`
  display: flex;
  flex: 1;
  background-color: black;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  bottom: 0;
  width: 100%;
  max-height: 140px;

  @media (min-width: 767px) {
    flex-direction: row;
  }
  img {
    max-width: 200px;
    width: 50%;
    height: 100%;
    margin: 20px auto;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `パスワードを入力するとミントボタンが表示されます`
  );
  const [mintAmount, setMintAmount] = useState(1);
  const [mintedWlA, setmintedWlA] = useState(0);
  const [wlA, setwlA] = useState(false);
  const [passwd, setpasswd] = useState("");
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [mintedWlAPswd, setmintedWlAPswd] = useState(0);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWLB: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const handleClick = (url, filename) => {
    alert("読むこむので少々お待ちください。");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const claimNFTsA = () => {
    let cost = CONFIG.WEI_COST;
    let amount = mintAmount;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * (amount - 1)); //1枚目フリーミント(ふりっきー)
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0919(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`${CONFIG.NFT_NAME}ミント中...`);
    setFeedback("");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(amount, whitelistProof, passwd)
      // .FreeMintB(1)
      // .psMint(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ミント失敗！時間をおいて試してみてください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `パスワードおめでとうございます🎉<br />BirthroomのMintに成功しました。<br />ご自身のOpenSeaで確認してみてください！！<br /><a href="https://twitter.com/intent/follow?screen_name=waboku2015" target="_blank" style="color:#00f400"><svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="none" class="twtr-color-fill--blue-logo"> 
 <path fill-rule="evenodd" clip-rule="evenodd" d="M7.04128 17.7861C4.53883 17.7861 2.21078 17.0602 0.25 15.8165C0.596807 15.857 0.949401 15.8773 1.30683 15.8773C3.38266 15.8773 5.29282 15.1764 6.80944 14.0003C4.87 13.9646 3.23461 12.6968 2.67056 10.9547C2.94041 11.0059 3.21803 11.0338 3.50342 11.0338C3.90767 11.0338 4.2993 10.9798 4.67133 10.8796C2.64431 10.4775 1.11689 8.70468 1.11689 6.5808C1.11689 6.56156 1.11689 6.54327 1.11792 6.52489C1.71505 6.85368 2.39787 7.05133 3.12448 7.07347C1.93514 6.28783 1.15299 4.94488 1.15299 3.42361C1.15299 2.62053 1.37213 1.86754 1.75297 1.21971C3.93781 3.87277 7.20298 5.61776 10.885 5.80097C10.8091 5.47987 10.7701 5.14535 10.7701 4.80118C10.7701 2.38039 12.7543 0.416626 15.2012 0.416626C16.4753 0.416626 17.6267 0.949734 18.4351 1.80197C19.4444 1.60535 20.392 1.23997 21.2484 0.737722C20.9172 1.76154 20.2148 2.62053 19.3002 3.1633C20.1963 3.0572 21.0506 2.82194 21.8444 2.47297C21.2512 3.35223 20.4993 4.12445 19.6342 4.7433C19.643 4.93129 19.6469 5.12031 19.6469 5.31018C19.6469 11.1042 15.1905 17.7861 7.04128 17.7861Z" fill="#1DA1F2"></path> 
</svg>@waboku2015</a>`
        );
        setClaimingNft(true);
        checkMintedwlA();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const checkWlA = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      console.dir(passwd);
      // let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/passwdlists.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(passwd);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      console.dir("root", root);
      console.dir(root);
      console.dir("proof");
      console.dir(proof);
      if (passwd !== "") {
        blockchain.smartContract.methods
          .isWhitelisted(passwd, proof)
          .call()
          .then((receipt) => {
            setwhitelistProof(proof);
            setwhitelistValid(receipt);
            console.log("whitelistValid/" + whitelistValid);
            dispatch(fetchData(blockchain.account));
            setwlA(receipt);
          });
      }
    }
  };

  const setWLSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMintedwlA();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };
  const checpswdMinted_A = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .pswdMinted(passwd)
        .call()
        .then((receipt) => {
          setmintedWlAPswd(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedwlA = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWlA(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const changePasswd = (event) => {
    const newVal = event.target.value;
    if (passwd != event.target.value) {
      setpasswd(newVal);
      setpasswd((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1 - mintedWlAPswd) {
      newMintAmount = 1 - mintedWlAPswd;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    checkMintedwlA();
    checkWlA();
  }, []);

  useEffect(() => {
    getData();
    checkMintedwlA();
    checkWlA();
  }, [blockchain.account]);

  useEffect(() => {
    checkWlA();
    checpswdMinted_A();
  }, [passwd]);

  return (
    <s.Screen
      image={CONFIG.SHOW_BACKGROUND ? "/config/images/shibuya.jpg" : null}
    >
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0,0,0,0.5)" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/shibuya.jpg" : null}
      >
        {/* <StyledLogo alt={"logo"} src={"/config/images/demo.png"} /> */}
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 50,
            paddingTop: 30,
            fontWeight: "bold",
            color: "#00f400",
            fontFamily: "dashDigital",
          }}
        >
          NFT ART
          <br />
          TOKYO
          <br />3
        </s.TextTitle>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/demo.png"} />
          </s.Container> */}
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              padding: 24,
              borderRadius: 24,
              border: "4px var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
                fontFamily: "dashDigital",
              }}
            >
              <s.SpacerXSmall />

              {data.totalSupply}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                fontFamily: "dashDigital",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />

            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {CONFIG.NFT_NAME}完売！！ ありがとうございました！
                </s.TextTitle>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "dashDigital",
                  }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "dashDigital",
                  }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                <s.SpacerXSmall />

                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "dashDigital",
                  }}
                >
                  {"FREE"}
                </s.TextTitle>
                {/* <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                  {"(Max 2 mint)"}
                </s.TextTitle> */}
                <s.SpacerXSmall />

                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "dashDigital",
                  }}
                >
                  +Gas Price
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontFamily: "dashDigital",
                      }}
                    >
                      Connect to {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        checkWlA();
                      }}
                    >
                      <label>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          パスワード:
                        </s.TextDescription>
                        <input
                          type="text"
                          style={{
                            height: 50,
                            textAlign: "center",
                            color: "#00f400",
                            backgroundColor: "#333",
                            fontSize: "1.3rem",
                          }}
                          onChange={(e) => {
                            e.preventDefault();
                            changePasswd(e);
                          }}
                        />
                      </label>
                      <StyledInput type="submit" value="check"></StyledInput>
                      {/* <input type="submit" value="check" /> */}
                    </form>

                    {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"このパスワードでのミント済数:" + mintedWlAPswd}
                    </s.TextDescription> */}
                    <s.SpacerMedium />

                    <s.TextDescription
                      className="feedback"
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: feedback }} />
                    </s.TextDescription>
                    <s.SpacerMedium />

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontFamilyt:
                          '"coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important',
                      }}
                    >
                      {"MINTはウォレットにつき一回しかできません"}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    {/* <s.Container ai={"center"} jc={"center"} fd={"row"}>
                     
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {2}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      
                    </s.Container>
                    <s.SpacerSmall /> */}
                    <s.Container>
                      {/* Aここから */}
                      {wlA > 0 ? ( //Aホワイトリスト所有確認
                        <>
                          {data.wlSaleStart_A ? ( //セールA開始確認
                            <>
                              {mintedWlA >= wlA || mintedWlAPswd > 0 ? ( //ミント済確認
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      {"MINTED"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.SpacerXSmall />
                                    <StyledRoundButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount();
                                      }}
                                    >
                                      -
                                    </StyledRoundButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                      }}
                                    >
                                      {mintAmount}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledRoundButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                      }}
                                    >
                                      +
                                    </StyledRoundButton>
                                  </s.Container>
                                  <s.SpacerMedium />

                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTsA();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "Minting..." : "MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <s.TextDescription
                                  style={{
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"WLに登録されています。"}
                                </s.TextDescription>
                              </s.Container>

                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <s.TextDescription
                                  style={{
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"ComingSoon."}
                                </s.TextDescription>
                              </s.Container>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* Aここまで */}
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/demo.png"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          {/* オペレーターコマンド */}
          {blockchain.account === "" ||
          blockchain.smartContract === null ||
          blockchain.account === undefined ? (
            <></>
          ) : (
            <>
              {data.deployer == blockchain.account ? (
                <>
                  <div
                    // ai={"center"}
                    style={
                      {
                        // display: "flex",
                        // justifyContent: "center",
                      }
                    }
                  >
                    {!data.wlSaleStart_A ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setWLSaleEnable(true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "WL.Start"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setWLSaleEnable(false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "WL.Stop"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {/* オペレーターコマンド */}
        </s.Container>
      </s.Container>
      <ResponsiveFooter>
        <img src="/config/images/spacer.png" />
      </ResponsiveFooter>
    </s.Screen>
  );
}

export default App;
